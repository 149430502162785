<template>
    <div class="container-fluid bg-secondary py-4">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md text-primary text-center text-lg-left mb-3 mb-lg-0 d-flex flex-column flex-lg-row align-items-center">
                    <img src="../assets/logo-footer.svg" width="50">
                    <p class="mb-0 ml-3">{{$t("footer_copy")}}<a target="_blank" href="https://eeas.europa.eu/delegations/un-rome_en">{{$t("eu_title")}}</a><br>{{$t("all_rights_reserved")}}</p>
                </div>
                <div class="col-12 col-md text-center mb-3 mb-lg-0">
                    <a class="mx-2" href="https://www.facebook.com/delegationofeuropeanunionrome" target="_blank">
                        <img class="iconFooter" src="../assets/facebook.svg">
                    </a>
                    <a class="mx-2" href="https://twitter.com/eudeleg_rome" target="_blank">
                        <img class="iconFooter" src="../assets/twitter.svg">
                    </a>
                </div>
                <div class="col-12 col-md text-center text-lg-right">
                    <p class="mb-0">{{$t("created_by")}}<a href="https://www.studiopolpo.it/" target="blank">Studio Polpo</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="sass">
</style>

<template>
  <div class="home">
    <div class="container-fluid bg-primary pt-2 pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 text-center text-white">
            <h1 class="text-secondary">{{$t("home_titolo")}}</h1>
            <h2>{{$t("home_sottotitolo")}}</h2>
            <router-link to="/le-chiese" class="btn btn-outline-secondary mt-3">{{$t("esplora_le_chiese")}}</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 position-relative wrapperChurches bg-home">
      <img class="churches church1" src="../assets/church-home-1.svg">
      <img class="churches church2" src="../assets/church-home-2.svg">
      <img class="churches church3" src="../assets/church-home-3.svg">
      <img class="churches church4" src="../assets/church-home-4.svg">
      <img class="churches church5" src="../assets/church-home-5.svg">
      <img class="churches walker1" src="../assets/walker-1.svg">
      <img class="churches walker2" src="../assets/walker-2.svg">
      <img class="churches walker3" src="../assets/walker-3.svg">
    </div>

    <div class="container-fluid py-5 position-relative bg-white">
      <img class="churches walker4" src="../assets/walker-3.svg">
      <img class="churches walker5" src="../assets/walker-2.svg">
      <div class="container py-5">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 text-center text-primary">
            <h2>{{$t("home_sottotitolo")}}</h2>
            <p>{{$t("par_home")}}</p>
            <router-link to="/il-cammino" class="btn btn-outline-secondary text-primary mt-3">{{$t("scopri_il_cammino")}}</router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid bg-white">
      <div class="container pb-5 pt-3">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 text-center">
            <h2 class="text-primary mb-4">{{$t("home_sottotitolo")}}</h2>
            <div class="ratio ratio-16x9">
              <iframe class="rounded shadow" width="100%" height="400" src="https://www.youtube.com/embed/waAlgFq9Xq8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>            </div>
          </div>
        </div>
      </div>
    </div> -->

    <Map/>

  </div>
</template>

<script>
import Map from '@/components/Map.vue'

export default {
  name: 'Home',
  components: {
    Map
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<template>
    <div class="wrapperMap">
        <l-map class="leafletMap" :zoom="zoom" :center="center" ref="mymap">
            <div class="overlay"></div>
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker :lat-lng="austria">
              <l-popup>
                <a href="https://www.google.com/maps/place/S.+Maria+dell'Anima+-+Pontificio+Istituto+Teutonico/@41.8995645,12.4696775,17z/data=!3m1!5s0x132f605ab2801baf:0xb1398a576730c0bc!4m9!1m2!2m1!1sSanta+Maria+dell'Anima!3m5!1s0x132f604554ab7cf1:0x546ba30987705518!8m2!3d41.8997551!4d12.4720966!15sChZTYW50YSBNYXJpYSBkZWxsJ0FuaW1hkgEPY2F0aG9saWNfY2h1cmNo" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.austria.nazione")}}</a>
                <br>
                {{$t("churches.austria.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="belgio">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+San+Giuliano+dei+Fiamminghi/@41.8959258,12.473577,17z/data=!4m12!1m6!3m5!1s0x132f604efb529c41:0x378d141a1a2e9567!2sChiesa+San+Giuliano+dei+Fiamminghi!8m2!3d41.895923!4d12.4757658!3m4!1s0x132f604efb529c41:0x378d141a1a2e9567!8m2!3d41.895923!4d12.4757658" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.belgio.nazione")}}</a>
                <br>
                {{$t("churches.belgio.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="bulgaria">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+San+Paolo+alla+Regola/@41.8930514,12.4713627,17z/data=!3m1!4b1!4m5!3m4!1s0x132f604895e368ab:0x97a9a577ca03103e!8m2!3d41.8930662!4d12.4735766" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.bulgaria.nazione")}}</a>
                <br>
                {{$t("churches.bulgaria.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="cechia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Basilica+di+San+Clemente/@41.8852118,12.5004593,15z/data=!4m9!1m2!2m1!1zU2FuIENsZW1lbnRlIAkJCQkJCQk!3m5!1s0x132f61b84d7e5de9:0x97e138e66d21b151!8m2!3d41.8893347!4d12.4975757!15sCgxTYW4gQ2xlbWVudGVaHAoMc2FuIGNsZW1lbnRlIgxzYW4gY2xlbWVudGWSAQhiYXNpbGljYQ" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.cechia.nazione")}}</a>
                <br>
                {{$t("churches.cechia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="cipro">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+Ortodossa+di+San+Teodoro+al+Palatino/@41.8905854,12.4822992,17z/data=!3m2!4b1!5s0x132f61b527d132d5:0x455a5b254c06d79c!4m5!3m4!1s0x132f61b52851a34b:0x6bb210a906e23a9b!8m2!3d41.8905814!4d12.4844879" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.cipro.nazione")}}</a>
                <br>
                {{$t("churches.cipro.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="croazia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+San+Girolamo+dei+Croati/@41.9050625,12.4736179,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6056c2324c0b:0xb09856a5962d79cf!8m2!3d41.9049574!4d12.4757933" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.croazia.nazione")}}</a>
                <br>
                {{$t("churches.croazia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="danimarca">
              <l-popup>
                <a href="https://www.google.com/maps/place/Camposanto+Teutonico/@41.9013121,12.4523578,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6065f7f60b69:0xa73646509bfaa569!8m2!3d41.9013081!4d12.4545465" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.danimarca.nazione")}}</a>
                <br>
                {{$t("churches.danimarca.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="estonia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Camposanto+Teutonico/@41.9013121,12.4523578,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6065f7f60b69:0xa73646509bfaa569!8m2!3d41.9013081!4d12.4545465" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.estonia.nazione")}}</a>
                <br>
                {{$t("churches.estonia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="finlandia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Basilica+di+Santa+Maria+Sopra+Minerva/@41.8979928,12.4758339,17z/data=!3m1!4b1!4m5!3m4!1s0x132f604e0c1fe953:0x4ec75fd47747b4d4!8m2!3d41.8979888!4d12.4780226" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.finlandia.nazione")}}</a>
                <br>
                {{$t("churches.finlandia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="francia">
              <l-popup>
                <a href="https://www.google.com/maps/place/San+Luigi+dei+Francesi/@41.899585,12.4723518,17z/data=!3m1!4b1!4m5!3m4!1s0x132f60502e82012d:0x22b3fa4bf22984b9!8m2!3d41.8995929!4d12.4748011" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.francia.nazione")}}</a>
                <br>
                {{$t("churches.francia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="germania">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+Evangelica+Luterana/@41.8996196,12.4570309,14z/data=!4m9!1m2!2m1!1sChiesa+del+Cristo+(evangelica+luterana)%09%09%09!3m5!1s0x132f610710997e7b:0x60def265c08fad7f!8m2!3d41.9088472!4d12.491775!15sCidDaGllc2EgZGVsIENyaXN0byAoZXZhbmdlbGljYSBsdXRlcmFuYSmSARJldmFuZ2VsaWNhbF9jaHVyY2g" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.germania.nazione")}}</a>
                <br>
                {{$t("churches.germania.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="grecia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+Ortodossa+di+San+Teodoro+al+Palatino/@41.8905854,12.4822992,17z/data=!3m2!4b1!5s0x132f61b527d132d5:0x455a5b254c06d79c!4m5!3m4!1s0x132f61b52851a34b:0x6bb210a906e23a9b!8m2!3d41.8905814!4d12.4844879" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.grecia.nazione")}}</a>
                <br>
                {{$t("churches.grecia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="irlanda">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+Sant'Isidoro+a+Capo+le+Case/@41.9059386,12.4849361,17z/data=!3m1!4b1!4m5!3m4!1s0x132f61aa595ea72d:0x936046d926848e68!8m2!3d41.9059354!4d12.4871242" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.irlanda.nazione")}}</a>
                <br>
                {{$t("churches.irlanda.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="italia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Santa+Maria+degli+Angeli+e+dei+Martiri/@41.9031928,12.4947454,17z/data=!3m1!4b1!4m5!3m4!1s0x132f61a61a06f449:0xb04e9bd531ba0c30!8m2!3d41.9031888!4d12.4969341" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.italia.nazione")}}</a>
                <br>
                {{$t("churches.italia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="lettonia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Basilica+e+Monastero+Agostiniano+Santi+Quattro+Coronati/@41.888577,12.4961381,17z/data=!3m1!4b1!4m5!3m4!1s0x132f61b85c7a69f7:0xadb513e81c5afcc!8m2!3d41.8885654!4d12.4983222" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.lettonia.nazione")}}</a>
                <br>
                {{$t("churches.lettonia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="lituania">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+del+Ges%C3%B9/@41.8959284,12.4776736,17z/data=!3m1!4b1!4m5!3m4!1s0x132f604c37825f2b:0xbd2f4ff9d307e966!8m2!3d41.8958257!4d12.4793667" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.lituania.nazione")}}</a>
                <br>
                {{$t("churches.lituania.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="lussemburgo">
              <l-popup>
                <a href="https://www.google.com/maps/place/Basilica+del+Sacro+Cuore+di+Ges%C3%B9/@41.9025298,12.5001862,17z/data=!3m1!5s0x132f61a1178e2ae3:0x358af71366b97534!4m9!1m2!2m1!1zU2Fjcm8gQ3VvcmUgZGkgR2Vzw7kJCQkJCQ!3m5!1s0x132f61a13f960c37:0xeb9d5c2c2f32f987!8m2!3d41.9025298!4d12.5023749!15sChRTYWNybyBDdW9yZSBkaSBHZXPDuVosChRzYWNybyBjdW9yZSBkaSBnZXPDuSIUc2Fjcm8gY3VvcmUgZGkgZ2Vzw7mSAQhiYXNpbGljYZoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOemFrbHVTM0JSUlJBQg" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.lussemburgo.nazione")}}</a>
                <br>
                {{$t("churches.lussemburgo.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="malta">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+Santa+Maria+del+Priorato/@41.8832734,12.4751556,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6031629abd07:0x75a613e424b41cc0!8m2!3d41.8832518!4d12.4773188" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.malta.nazione")}}</a>
                <br>
                {{$t("churches.malta.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="paesi_bassi">
              <l-popup>
                <a href="https://www.google.com/maps/place/Santi+Michele+e+Magno/@41.9013508,12.4588351,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6067dacedfc1:0xbcdd09e3e9e0151c!8m2!3d41.9013506!4d12.4610208" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.paesi_bassi.nazione")}}</a>
                <br>
                {{$t("churches.paesi_bassi.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="polonia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+Ed+Ospizio+Di+S.+Stanislao/@41.8948511,12.4770901,17z/data=!3m2!4b1!5s0x132f604c1661ffdf:0xb90b78092b82cd9f!4m5!3m4!1s0x132f604c1612e8a5:0x85b74f0648da6524!8m2!3d41.8948471!4d12.4792788" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.polonia.nazione")}}</a>
                <br>
                {{$t("churches.polonia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="portogallo">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+Sant'Antonio+dei+Portoghesi/@41.9017692,12.4723361,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6050f775bdc1:0xe1ec02c909c662c2!8m2!3d41.9017688!4d12.4745246" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.portogallo.nazione")}}</a>
                <br>
                {{$t("churches.portogallo.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="romania">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+San+Salvatore+alle+Coppelle/@41.9005905,12.4738439,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6051b0b62731:0x40a934980de9e082!8m2!3d41.9005867!4d12.4760249" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.romania.nazione")}}</a>
                <br>
                {{$t("churches.romania.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="slovacchia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Via+Maria+Domenica+Brun+Barbantini,+31,+00123+Roma+RM/@41.989662,12.3945006,17z/data=!3m1!4b1!4m5!3m4!1s0x132f5dac14ff5fe9:0x4d52f04f83f6154b!8m2!3d41.989658!4d12.3966893" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.slovacchia.nazione")}}</a>
                <br>
                {{$t("churches.slovacchia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="slovenia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Basilica+Papale+di+Santa+Maria+Maggiore/@41.8968753,12.4926385,15.44z/data=!4m9!1m2!2m1!1zU2FudGEgTWFyaWEgTWFnZ2lvcmUJCQkJCQ!3m5!1s0x132f61a4db7d30d7:0xf7b354fb8f8c357a!8m2!3d41.8975986!4d12.4984084!15sChRTYW50YSBNYXJpYSBNYWdnaW9yZVosChRzYW50YSBtYXJpYSBtYWdnaW9yZSIUc2FudGEgbWFyaWEgbWFnZ2lvcmWSAQhiYXNpbGljYQ" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.slovenia.nazione")}}</a>
                <br>
                {{$t("churches.slovenia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="spagna">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+San+Pietro+in+Montorio/@41.8886977,12.4641872,17z/data=!3m1!4b1!4m5!3m4!1s0x132f603f333c90ef:0xd0fed33d1900ee7b!8m2!3d41.8886937!4d12.4663759" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.spagna.nazione")}}</a>
                <br>
                {{$t("churches.spagna.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="svezia">
              <l-popup>
                <a href="https://www.google.com/maps/place/Chiesa+di+Santa+Brigida/@41.8953035,12.468607,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6045dcc3c357:0xdf88d23c6162b73f!8m2!3d41.8952807!4d12.470827" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.svezia.nazione")}}</a>
                <br>
                {{$t("churches.svezia.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="ungheria">
              <l-popup>
                <a href="https://www.google.com/maps/place/Basilica+di+Santo+Stefano+Rotondo/@41.8845642,12.4945638,17z/data=!3m2!4b1!5s0x132f61c7c9eae805:0xd1b5eff7650a441b!4m5!3m4!1s0x132f61c7cae3e9fb:0xbd7d43e12d2fbcc2!8m2!3d41.8845602!4d12.4967525" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.ungheria.nazione")}}</a>
                <br>
                {{$t("churches.ungheria.nome")}}
              </l-popup>
            </l-marker>
            <l-marker :lat-lng="unione_europea">
              <l-popup>
                <a href="https://www.google.com/maps/place/Camposanto+Teutonico/@41.9013121,12.4523578,17z/data=!3m1!4b1!4m5!3m4!1s0x132f6065f7f60b69:0xa73646509bfaa569!8m2!3d41.9013081!4d12.4545465" target="_blank" class="text-uppercase font-weight-bold">{{$t("churches.unione_europea.nazione")}}</a>
                <br>
                {{$t("churches.unione_europea.nome")}}
              </l-popup>
            </l-marker>
        </l-map>
    </div>
</template>

<script>
import json from '../dataTest.json'
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  data() {
    return {
      myJson: json,
      chiesa: null,
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=d0ec463c-851d-4adf-b6c1-24390a29c34b',
      zoom: 13,
      center: L.latLng(41.90186100365954, 12.475093426212197),
      austria: L.latLng(41.89964324923122, 12.472137370976325),
      belgio: L.latLng(41.89613740373222, 12.475840799811037),
      bulgaria: L.latLng(41.89323106767344, 12.473626499811004),
      cechia: L.latLng(41.889390587710146, 12.497661528645684),
      cipro: L.latLng(41.89078104826555, 12.485303289412432),
      croazia: L.latLng(41.90511437394533, 12.476225022524975),
      danimarca: L.latLng(41.901427860603455, 12.454610870976357),
      estonia: L.latLng(41.901427860603455, 12.454610870976357),
      finlandia: L.latLng(41.898156481809636, 12.478580497377317),
      francia: L.latLng(41.899764648881906, 12.475377347082212),
      germania: L.latLng(41.909616800547795, 12.494023948343598),
      grecia: L.latLng(41.89078104826555, 12.485303289412432),
      irlanda: L.latLng(41.90612621543687, 12.487918731743163),
      italia: L.latLng(41.90323669039113, 12.497953337273902),
      lettonia: L.latLng(41.88870875850913, 12.498991985725095),
      lituania: L.latLng(41.89591639311742, 12.479926670976262),
      lussemburgo: L.latLng(41.9028971031737, 12.505207351710164),
      malta: L.latLng(41.88341315745237, 12.477977299220575),
      paesi_bassi: L.latLng(41.90149051676548, 12.462032308439158),
      polonia: L.latLng(41.895022776279205, 12.479901070386044),
      portogallo: L.latLng(41.90186100365954, 12.475093426212197),
      romania: L.latLng(41.900682305428376, 12.476558310872928),
      slovacchia: L.latLng(41.98976164600119, 12.397429587571038),
      slovenia: L.latLng(41.89759760070389, 12.498660061081784),
      spagna: L.latLng(41.888805497268855, 12.466944526211913),
      svezia: L.latLng(41.89544323037774, 12.471535987568936),
      ungheria: L.latLng(41.884656029475934, 12.497449872229433),
      unione_europea: L.latLng(41.901427860603455, 12.454610870976357)
    };
  },
}
</script>

<style scoped lang="sass">
</style>

<template>
  <div class="notFound">
    <div class="container-fluid bg-white">
      <div class="row justify-content-center align-items-center vh100 text-center">
        <div class="col-12 col-md-6">
          <h1 class="text-primary font-weight-bold">{{$t("page_not_found")}}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped lang="sass">
.vh100
  min-height: 70vh
  @media screen and (max-width: 480px)
    min-height: 77.5vh
</style>

<template>
    <div class="le-chiese">
        <div class="container-fluid bg-primary py-5 bg-le-chiese">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8 text-center text-white">
                        <h1><span class="text-secondary">{{$t("chiese_titolo")}}</span><br>{{$t("chiese_titolo_evidenziato")}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-primary pt-2 pb-5">
            <div class="container">
                <div class="row align-items-end">
                    <div class="singleChurch col-12 col-md-6 col-lg-4 text-center mb-3 mb-md-0" v-for="data in myJson" :key="data.nazione">
                        <router-link :to='"/le-chiese/"+data.nazione'>
                            <div class="d-flex flex-column">
                                <template>
                                    <img :src="require(`../assets/churches-vector/chiesa_${data.nazione}.svg`)">
                                </template>
                                <div class="borderChurch">
                                    <h4 class="text-uppercase">{{$t(`churches.${data.nazione}.nazione`)}}</h4>
                                    <h5 class="text-uppercase">{{$t(`churches.${data.nazione}.nome`)}}</h5>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import json from '../dataTest.json'

export default {
    name: 'LeChiese',
    data() {
        return {
            myJson: json
        }
    },
    mounted () {
        window.scrollTo(0, 0)
    },
}
</script>

<style lang="sass">
</style>

<template>
    <div class="il-cammino">
        <div class="container-fluid position-relative bg-primary py-5">
            <img class="churches churchCammino1" src="../assets/church-cammino-1.svg">
            <img class="churches churchCammino2" src="../assets/church-cammino-2.svg">
            <img class="churches walkerCammino1" src="../assets/walker-cammino-1.svg">
            <img class="churches walkerCammino2" src="../assets/walker-cammino-2.svg">
            <img class="churches walkerCammino3" src="../assets/walker-cammino-3.svg">
            <div class="container">
                <div class="row justify-content-start align-items-center text-white pb-0 pb-md-5">
                    <div class="col-12 col-lg-7 col-xl-8">
                        <h1 class="text-center text-lg-left">{{$t("cammino_titolo")}}<br><span class="text-secondary">{{$t("cammino_titolo_evidenziato")}}</span></h1>
                        <p class="mt-4">{{$t("par_cammino_1")}}</p>
                        <p class="mt-4">{{$t("par_cammino_1_1")}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-100 position-relative wrapperChurches wrapperChurchesCammino bg-camminoWrapper">
            <img class="churches churchCamminoMobile1" src="../assets/church-cammino-2.svg">
            <img class="churches churchCamminoMobile2" src="../assets/church-home-5.svg">
            <img class="churches walkerCamminoMobile1" src="../assets/walker-1.svg">
            <img class="churches walkerCamminoMobile2" src="../assets/walker-2.svg">
        </div>

        <div class="container-fluid py-5 position-relative bg-white">
            <img class="churches churchCammino3" src="../assets/church-cammino-3.svg">
            <img class="churches churchCammino4" src="../assets/church-home-4.svg">
            <img class="churches walkerCammino4" src="../assets/walker-1.svg">
            <div class="container">
                <div class="row justify-content-start align-items-center">
                    <div class="col-12 col-lg"></div>
                    <div class="col-12 col-lg-7">
                        <p>{{$t("par_cammino_2")}}</p>
                        <p>{{$t("par_cammino_2_2")}}</p>
                        <p class="font-weight-bold" v-html="$t('quote_pope')"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IlCammino',
    mounted () {
        window.scrollTo(0, 0)
    },
}
</script>

<style lang="sass">
</style>

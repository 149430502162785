import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    isOpen: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

export default store;
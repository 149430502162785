import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import IlCammino from '../views/IlCammino.vue'
import LeChiese from '../views/LeChiese.vue'
import ChiesaSingola from '../views/ChiesaSingola.vue'
import NotFound from '../views/404.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transitionName: 'slide' },
  },
  {
    path: '/il-cammino',
    name: 'IlCammino',
    component: IlCammino,
    meta: { transitionName: 'slide' },
  },
  {
    path: '/le-chiese',
    name: 'LeChiese',
    component: LeChiese,
    meta: { transitionName: 'slide' },
  },
  {
    path: '/le-chiese/:chiesa',
    name: 'ChiesaSingola',
    component: ChiesaSingola,
    meta: { transitionName: 'slide' },
  },
  { 
    path: '/404', 
    component: NotFound,
    meta: { transitionName: 'slide' },
  },
  { 
    path: '*', 
    component: NotFound 
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

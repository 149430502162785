<template>
  <div id="app">
    <Menu/>
    <!-- <router-view/> -->
    <transition
        :name="transitionName"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view/>
    </transition>
    <Footer/>
  </div>
</template>

<style lang="sass">

</style>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
const DEFAULT_TRANSITION = 'fade';

export default {
  components: {
    Menu,
    Footer,
  },
  data () {
    return { 
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
}
</script>

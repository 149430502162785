<template>
    <div class="container-fluid bg-primary py-4 wrapperMenu">
        <div class="container px-0">
            <transition name="fade">
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4 text-center">
                        <router-link to="/" @click="openMenu = !openMenu">
                            <img @mouseover="show = false" class="logo" src="../assets/logo-white.svg" alt="ITER">
                        </router-link>
                    </div>
                    <div class="col-4 text-right">
                        <b-icon class="d-inline-block d-lg-none" v-if="!openMenu && !openMenuInside" @click="openMenu = !openMenu" icon="list" variant="secondary" font-scale="2"></b-icon>
                        <b-icon class="d-inline-block d-lg-none" v-if="openMenu" @click="openMenu = !openMenu" icon="x-circle" variant="secondary" font-scale="2"></b-icon>
                        <b-icon class="d-inline-block d-lg-none" v-if="openMenuInside" @click="openMenuInside = !openMenuInside" icon="x-circle" variant="secondary" font-scale="2"></b-icon>
                    </div>
                </div>
            </transition>

            <div class="row mt-3 menu">
                <div class="col-12">
                    <div class="d-flex justify-content-center align-items-center">
                        <router-link class="mx-3 linkMenu" to="/"><span @mouseover="show = false">HOME</span></router-link>
                        <router-link class="mx-3 linkMenu" to="/il-cammino"><span @mouseover="show = false">{{$t("il_cammino")}}</span></router-link>
                        <router-link class="mx-3 linkMenu" to="/le-chiese"><span @mouseover="show = true" @click="show = false">{{$t("le_chiese")}}</span></router-link>
                        <a class="mx-3 linkMenu" @mouseover="show = false" target="_blank" href="/img/Iter_Europaeum-Guide.pdf">{{$t("sfoglia_la_guida")}}</a>
                        <a class="mx-3 linkMenu" @mouseover="show = false" target="_blank" href="/img/Iter_Europaeum-Map.pdf">{{$t("stampa_la_mappa")}}</a>
                        <b-form-select class="mx-3 customSelect pointer" v-model="$i18n.locale">
                            <b-form-select-option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                                <span v-if="lang === 'it'">Italiano</span>
                                <span v-else-if="lang === 'en'">English</span>
                                <span v-else>Français</span>
                            </b-form-select-option>
                        </b-form-select>
                    </div>   	   	    		  
                </div>
            </div>

            <transition name="fade">
                <div v-if="show" @mouseleave="show = false" class="row mt-3 menu menuHover text-center text-uppercase">
                    <div class="col d-flex flex-column justify-content-center">
                        <router-link to="/le-chiese/austria">{{$t("austria")}}</router-link>
                        <router-link to="/le-chiese/belgio">{{$t("belgio")}}</router-link>
                        <router-link to="/le-chiese/bulgaria">{{$t("bulgaria")}}</router-link>
                        <router-link to="/le-chiese/cechia">{{$t("cechia")}}</router-link>
                    </div>
                    <div class="col d-flex flex-column justify-content-center border-left border-secondary">
                        <router-link to="/le-chiese/cipro">{{$t("cipro")}}</router-link>
                        <router-link to="/le-chiese/croazia">{{$t("croazia")}}</router-link>
                        <router-link to="/le-chiese/danimarca">{{$t("danimarca")}}</router-link>
                        <router-link to="/le-chiese/estonia">{{$t("estonia")}}</router-link>
                    </div>
                    <div class="col d-flex flex-column justify-content-center border-left border-secondary">
                        <router-link to="/le-chiese/finlandia">{{$t("finlandia")}}</router-link>
                        <router-link to="/le-chiese/francia">{{$t("francia")}}</router-link>
                        <router-link to="/le-chiese/germania">{{$t("germania")}}</router-link>
                        <router-link to="/le-chiese/grecia">{{$t("grecia")}}</router-link>
                    </div>
                    <div class="col d-flex flex-column justify-content-center border-left border-secondary">
                        <router-link to="/le-chiese/irlanda">{{$t("irlanda")}}</router-link>
                        <router-link to="/le-chiese/italia">{{$t("italia")}}</router-link>
                        <router-link to="/le-chiese/lettonia">{{$t("lettonia")}}</router-link>
                        <router-link to="/le-chiese/lituania">{{$t("lituania")}}</router-link>
                    </div>
                    <div class="col d-flex flex-column justify-content-center border-left border-secondary">
                        <router-link to="/le-chiese/lussemburgo">{{$t("lussemburgo")}}</router-link>
                        <router-link to="/le-chiese/malta">{{$t("malta")}}</router-link>
                        <router-link to="/le-chiese/paesi_bassi">{{$t("paesi_bassi")}}</router-link>
                        <router-link to="/le-chiese/polonia">{{$t("polonia")}}</router-link>
                    </div>
                    <div class="col d-flex flex-column justify-content-center border-left border-secondary">
                        <router-link to="/le-chiese/portogallo">{{$t("portogallo")}}</router-link>
                        <router-link to="/le-chiese/romania">{{$t("romania")}}</router-link>
                        <router-link to="/le-chiese/slovacchia">{{$t("slovacchia")}}</router-link>
                        <router-link to="/le-chiese/slovenia">{{$t("slovenia")}}</router-link>
                    </div>
                    <div class="col d-flex flex-column justify-content-center border-left border-secondary">
                        <router-link to="/le-chiese/spagna">{{$t("spagna")}}</router-link>
                        <router-link to="/le-chiese/svezia">{{$t("svezia")}}</router-link>
                        <router-link to="/le-chiese/ungheria">{{$t("ungheria")}}</router-link>
                        <router-link to="/le-chiese/unione_europea">{{$t("unione_europea")}}</router-link>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="openMenu" class="container-fluid mt-3">
                    <div class="row vh100 menu d-flex align-items-center flex-column text-center">
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link class="text-secondary" to="/"><span @click="openMenu = !openMenu">HOME</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link class="text-secondary" to="/il-cammino"><span @click="openMenu = !openMenu">{{$t("il_cammino")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link class="text-secondary" to="/le-chiese"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("le_chiese")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <a class="text-secondary" target="_blank" href="/img/Iter_Europaeum-Guide.pdf" @click="openMenu = !openMenu">{{$t("sfoglia_la_guida")}}</a>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <a class="text-secondary" target="_blank" href="/img/Iter_Europaeum-Map.pdf" @click="openMenu = !openMenu">{{$t("stampa_la_mappa")}}</a>
                        </div>
                        <div class="col-12 py-2 mr-n2">
                            <b-form-select class="text-secondary customSelect" v-model="$i18n.locale">
                                <b-form-select-option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                                    <span v-if="lang === 'it'">Italiano</span>
                                    <span v-else-if="lang === 'en'">English</span>
                                    <span v-else>Français</span>
                                </b-form-select-option>
                            </b-form-select>
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="openMenuInside" class="container-fluid mt-3">
                    <div class="row vh100 menu d-flex align-items-center flex-column text-center">
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link class="text-secondary" to="/le-chiese"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("le_chiese")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/austria"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("austria")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/belgio"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("belgio")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/bulgaria"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("bulgaria")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/cechia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("cechia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/cipro"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("cipro")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/croazia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("croazia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/danimarca"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("danimarca")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/estonia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("estonia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/finlandia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("finlandia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/francia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("francia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/germania"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("germania")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/grecia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("grecia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/irlanda"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("irlanda")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/italia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("italia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/lettonia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("lettonia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/lituania"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("lituania")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/lussemburgo"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("lussemburgo")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/malta"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("malta")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/paesi_bassi"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("paesi_bassi")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/polonia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("polonia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/portogallo"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("portogallo")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/romania"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("romania")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/slovacchia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("slovacchia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/slovenia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("slovenia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/spagna"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("spagna")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/svezia"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("svezia")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3 border-bottom border-secondary">
                            <router-link to="/le-chiese/ungheria"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("ungheria")}}</span></router-link>
                        </div>
                        <div class="col-12 py-3">
                            <router-link to="/le-chiese/unione_europea"><span @click="(openMenuInside = !openMenuInside) && (openMenu = !openMenu)">{{$t("unione_europea")}}</span></router-link>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return { 
        show: false,
        openMenu: false,
        openMenuInside: false,
        langs: ['it', 'en', 'fr'] 
        }
    },
}
</script>

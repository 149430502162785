<template>
    <div v-if="chiesa" class="le-chiese">
        <div class="container-fluid bg-primary py-5">
            <div class="container pb-5">
                <div class="row align-items-center">
                    <div class="col-12 col-md-8 text-white text-center text-lg-left mb-5 mb-md-0">
                        <h1 class="text-uppercase">{{$t(`churches.${chiesa.nazione}.nazione`)}}</h1>
                        <h2 class="tetx-uppercase"><span class="text-secondary">{{$t(`churches.${chiesa.nazione}.nome`)}}</span></h2>
                        <h2>{{$t(`churches.${chiesa.nazione}.address`)}}</h2>
                        <h6 class="mt-3 text-break"><span v-if="chiesa.prenota">{{$t(`churches.${chiesa.nazione}.prenotaVisita`)}}</span> <a :href="'http://' + chiesa.link" target="_blank" class="text-secondary">{{chiesa.link}}</a></h6>
                        <a v-if="chiesa.descriptionMo && chiesa.btn" href="#info" class="btn btn-outline-secondary mt-3 text-uppercase">{{chiesa.btn}}</a>
                        <a v-if="chiesa.descriptionMo2 && chiesa.btn2" href="#info2" class="btn btn-outline-secondary mt-3 ml-3 text-uppercase">{{chiesa.btn2}}</a>
                        <!-- <a v-if="chiesa.descriptionMo" href="#info" class="btn btn-outline-secondary mt-3">{{$t("btn_lingua_originale")}}</a> -->
                    </div>
                    <div class="col-12 col-md d-flex justify-content-center align-items-center">
                        <img class="mx-auto img-fluid" :src="require(`../assets/churches-single/chiesa_${chiesa.nazione}.svg`)" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-primary pb-5">
            <div class="container">
                <div class="row align-items-center justify-content-between">
                    <div class="col-12 col-md-6 text-white" v-if="chiesa.descriptionIt || chiesa.descriptionEn || chiesa.descriptionFr">
                        <template>
                            <p>{{$t(`churches.${chiesa.nazione}.description`)}}</p>
                        </template>
                    </div>
                    <div class="col-12 col-md text-white px-3 px-md-5">  
                        <carousel :navigationEnabled="true" :perPage="1">
                            <template v-if="chiesa.nazione === 'austria' || chiesa.nazione === 'belgio'">
                                <slide class="d-flex justify-content-center align-items-center" v-for="index in 3" :key="index">
                                    <img class="img-fluid" :src="require(`../assets/churches-carousel/${chiesa.nazione}_foto_`+ index +`.jpg`)">
                                </slide>
                            </template>
                            <template v-else>
                                <slide class="d-flex justify-content-center align-items-center" v-for="index in 4" :key="index">
                                    <img class="img-fluid" :src="require(`../assets/churches-carousel/${chiesa.nazione}_foto_`+ index +`.jpg`)">
                                </slide>
                            </template>
                        </carousel>  
                    </div>
                </div>
            </div>
        </div>

        <Map/>

        <div class="container-fluid py-5 bg-white" id="info" v-if="chiesa.descriptionMo">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <p>{{$t(`churches.${chiesa.nazione}.descriptionMo`)}}</p>
                        <template v-if="chiesa.descriptionMo2">
                            <br id="info2">
                            <p>{{$t(`churches.${chiesa.nazione}.descriptionMo2`)}}</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import json from '../dataTest.json'
import router from  '../router'
import Map from '@/components/Map.vue'
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'LeChiese',
    components: {
        Map,
        Carousel,
        Slide
    },
    data() {
        return {
            myJson: json,
            chiesa: null,
        }
    },
    watch: {
        '$route': 'fetchData'
    },
    created () {
        this.fetchData()
    },
    mounted () {
        window.scrollTo(0, 0)
    },
    methods: {
        fetchData () {
            let nazione = this.$route.params.chiesa;
            this.chiesa = this.myJson.find((e) => e.nazione == nazione);
            if (!this.chiesa) {
                router.push('/404')
            }
        }
    }
}
</script>

<style lang="sass">
</style>
